









import { mountComponent } from 'library/helpers/dom'
import { mergeShopifyProductCard } from 'library/helpers/products'
import QuickshopModal from '~/components/globals/product/quick-shop/index'

export default

	props:
		button: Object
		size:
			type: String
			default: 'semi-wide'

	data: ->
		product: null
		loaded: false

	fetch: ->
		return unless craftProduct = @button?.product?[0]
		@product = await mergeShopifyProductCard craftProduct

	methods:

		openModal: ->
			await mountComponent QuickshopModal,
				parent: this
				propsData: { @product }

