











import QuickShopContent from '~/components/globals/product/quick-shop/content'

export default
	components: { QuickShopContent }

	props:
		product: Object

	data: ->
		loaded: false

	# focus on the modal's contents when mounted
	mounted: ->
		@$refs.modal.$el.querySelector('.bvm-slot').focus()
		@$nextTick =>
			@loaded = true

	methods:
		closed: ->
			@$refs.modal.close()


