import Cookies from 'js-cookie'
import { ONETRUST_COOKIE_NAME, ONETRUST_CATEGORIES } from './onetrust-constants'
import { getOneTrust } from './onetrust-loading'
import { wait } from '@bkwld/cloak/services/helpers'

# Re-export constants to simplify using onConsent
export { ONETRUST_CATEGORIES }

# Invoke callback when consent is provided by polling for the cookie
# value.  I'm not using OneTrust.OnConsentChanged because it did not dependably
# invoke mny callbacks.
export onConsent = (consentId, callback) ->
	await getOneTrust() # Wait for cookie to be registered
	console.log 'onConsent'
	checkConsent = ->
		if consentEnabled consentId
		then callback()
		else wait 500, checkConsent # Check at a slower rate
	checkConsent()

# Check if user has approved a OneTrust cookie category.  This is similar to
# what we do in GTM.
consentEnabled = (consentId) ->
	return false unless oneTrustCookie = Cookies.get ONETRUST_COOKIE_NAME
	return oneTrustCookie.includes("#{consentId}:1")

# Poll the cookie for changes to consent since I don't trust the
# OneTrust.OnConsentChanged() method. The callback is invoked with an array
# OneTrust consent IDs
export whenConsentChanges = (callback) ->

	# Wait for cookie to be registered
	await getOneTrust()

	# Fire callback with the initial state
	enabledConsentIds = getEnabledConsentIds()
	callback enabledConsentIds

	# Poll for changes
	setInterval ->
		currentEnabledConsentIds = getEnabledConsentIds()

		# If the ids are different, invoke callback
		if currentEnabledConsentIds.join(',') != enabledConsentIds.join(',')
			enabledConsentIds = currentEnabledConsentIds
			callback enabledConsentIds

	# Check every second
	, 1000

# Make an array of the enabled categories from the cookie
getEnabledConsentIds = ->
	return [] unless oneTrustCookie = Cookies.get ONETRUST_COOKIE_NAME
	Object.values(ONETRUST_CATEGORIES).map (consentId) ->
		return consentId if oneTrustCookie.includes "#{consentId}:1"
	.filter Boolean
