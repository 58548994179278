









































import PurchaseOptions from '~/components/globals/product/purchase-options'

export default
	components: { PurchaseOptions }

	props:
		product: Object

	data: ->
		currentVariant: @product?.variants?[0]
		loaded: false

	mounted: -> @loaded = true

	methods:

		updateVariant: (variant) -> @currentVariant = variant

	computed:
		# deeplink url
		productUrl: -> "/products/#{@slug}"

		slug: -> @product.slug || @product.handle

